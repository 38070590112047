// Photo imports
import bbq_2_topping_rice from "./FeatureItemImages/bbq_2_topping_rice.png";
import bbq_3_topping_rice from "./FeatureItemImages/bbq_3_topping_rice.png";
import soya_chicken_rice from "./FeatureItemImages/soya_chicken_rice.png";
import bbq_pork_rice from "./FeatureItemImages/bbq_pork_rice.png";
import bbq_rib_rice from "./FeatureItemImages/bbq_rib_rice.png";
import roast_pork_rice from "./FeatureItemImages/roast_pork_rice.png";
import template from "../kingdo_homepage_background.png";
import soya_chicken from "./FeatureItemImages/soya_chicken.png";
import bbq_duck_rice from "./FeatureItemImages/bbq_duck_rice.png";

export const featuredItems = {
  menuSectionName: "FEATURED",
  menuSectionItems: [
    {
      id: 184,
      name: "BBQ 2 Topping Rice",
      price: 18.95,
      isSpicy: false,
      chineseTranslation: "烧味双拼饭",
      menuItemNumber: 0,
      pathToPhoto: bbq_2_topping_rice,
    },
    {
      id: 185,
      name: "Soya Chicken (HALF)",
      price: 17.95,
      isSpicy: false,
      chineseTranslation: "油鸡",
      menuItemNumber: 0,
      pathToPhoto: soya_chicken,
    },
    {
      id: 186,
      name: "Soya Chicken (WHOLE)",
      price: 28.95,
      isSpicy: false,
      chineseTranslation: "油鸡",
      menuItemNumber: 0,
      pathToPhoto: soya_chicken,
    },
    {
      id: 187,
      name: "BBQ Pork Rice",
      price: 14.95,
      isSpicy: false,
      chineseTranslation: "蜜汁叉烧饭",
      menuItemNumber: 0,
      pathToPhoto: bbq_pork_rice,
    },
    {
      id: 188,
      name: "Roast Pork Rice",
      price: 15.95,
      isSpicy: false,
      chineseTranslation: "脆皮烧肉饭",
      menuItemNumber: 0,
      pathToPhoto: roast_pork_rice,
    },
    {
      id: 189,
      name: "BBQ Duck (HALF)",
      price: 26.95,
      isSpicy: false,
      chineseTranslation: "明爐烧鸭",
      menuItemNumber: 0,
      pathToPhoto: template,
    },
    {
      id: 190,
      name: "BBQ Duck (WHOLE)",
      price: 46.95,
      isSpicy: false,
      chineseTranslation: "明爐烧鸭",
      menuItemNumber: 0,
      pathToPhoto: template,
    },
    {
      id: 191,
      name: "Roast Pork (WHOLE)",
      price: 15.95,
      isSpicy: false,
      chineseTranslation: "脆皮烧肉",
      menuItemNumber: 0,
      pathToPhoto: template,
    },
    {
      id: 192,
      name: "BBQ Pork (WHOLE)",
      price: 12.95,
      isSpicy: false,
      chineseTranslation: "蜜汁叉烧",
      menuItemNumber: 0,
      pathToPhoto: template,
    },
    {
      id: 193,
      name: "Express Chicken (HALF)",
      price: 20.95,
      isSpicy: false,
      chineseTranslation: "贵妃鸡",
      menuItemNumber: 0,
      pathToPhoto: template,
    },
    {
      id: 194,
      name: "Express Chicken (WHOLE)",
      price: 37.80,
      isSpicy: false,
      chineseTranslation: "贵妃鸡",
      menuItemNumber: 0,
      pathToPhoto: template,
    },
    {
      id: 195,
      name: "BBQ Duck Rice",
      price: 16.95,
      isSpicy: false,
      chineseTranslation: "明爐烧鸭饭",
      menuItemNumber: 0,
      pathToPhoto: bbq_duck_rice,
    },
    {
      id: 196,
      name: "Soya Chicken Rice",
      price: 13.95,
      isSpicy: false,
      chineseTranslation: "油鸡饭",
      menuItemNumber: 0,
      pathToPhoto: soya_chicken_rice,
    },
    {
      id: 197,
      name: "BBQ 3 Topping Rice",
      price: 21.95,
      isSpicy: false,
      chineseTranslation: "烧味三拼饭",
      menuItemNumber: 0,
      pathToPhoto: bbq_3_topping_rice,
    },
    {
      id: 198,
      name: "Daily Soup",
      price: 18.95,
      isSpicy: false,
      chineseTranslation: "例汤",
      menuItemNumber: 0,
      pathToPhoto: template,
    },
    {
      id: 199,
      name: "Stir Fried Vegetables",
      price: 16.95,
      isSpicy: false,
      chineseTranslation: "油菜",
      menuItemNumber: 0,
      pathToPhoto: template,
    },
  ],
};
